<template>
	<div
		class=""
	>
		<div
			class=" pb-20 text-center"
		>
			<div
				class="width-480-pc ma-auto   pb-20"
			>
				<p class=" mt-50 color-red size-px-24 font-weight-bold">회원가입을 축하드립니다</p>

				<div class="text-left mt-30">
					<h5 class="color-primary">가입 정보</h5>
					<table class="table mt-10">
						<col width="180px" class="hide-m"/>
						<col width="120px" class="hide-pc"/>
						<col width="auto" />
						<tbody>
							<tr>
								<th>아이디</th>
								<td>{{ item.member_id }}</td>
							</tr>
							<tr>
								<th>이메일</th>
								<td>{{ item.member_email }}</td>
							</tr>
							<tr>
								<th>휴대전화</th>
								<td>{{ item.member_tell || item.member_phone }}</td>
							</tr>
							<tr>
								<th>주소</th>
								<td>{{ item.member_post }} {{ item.member_addr1 }} {{ item.member_addr2 }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div
				class="mt-50 justify-space-between-m"
			>
				<button
					class="btn btn-fill mr-10 btn-s-m flex-1"
					@click="toLogin"
				>로그인</button>

				<button
					class="btn btn-outline btn-s-m flex-1"
					@click="toMain"
				>메인으로</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'JoinResult'
	,props: ['user']
	,data: function(){
		return {
			program:{
				name: '회원가입'
				,top: true
				, title: true
				, bottom: true
			}
			,app_type: localStorage.getItem('app_type')
			, item: this.user
		}
	}
	,methods: {

		toLogin: function(){
			try {

				const NUA = navigator.userAgent.toLowerCase()

				if(NUA.indexOf('android') > -1){
					window.Android.finishJoin()
				}else if(NUA.indexOf('iphone') > -1 || NUA.indexOf('ipad') > -1 || NUA.indexOf('ipod') > -1){
					window.webkit.messageHandlers.callback.postMessage('finishJoin')
				}else{
					this.$router.push({ name: 'Login'})
				}
			}catch(e){
				this.$router.push({ name: 'Login'})
			}
		}
		, toMain: function(){
			this.$bus.$emit('to', { name: 'Index'})
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
	}

}
</script>

<style>
.text-center { text-align: center !important;}
.txt-blue { color: #0f6ecd !important;}
</style>